<template>
  <div class="pt-3">
    <Loader v-if="!skill" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <div>
          <h4>Edit Skill:</h4>
          <div class="mb-3">
            <label for="title">Title</label>
            <input type="text" v-model="skill.title"></input>
          </div>
          <div class="mb-3">
            <label for="description">Description</label>
            <input type="text" v-model="skill.description"></input>
          </div>
          <div class="mb-3">
            <label for="category">Choose Category</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="skillCategories"
              v-model="skill.category"
              :clearable="false"
               multiple
              >
            </v-select>
          </div>
          <div class="mb-5">
            <label for="certs">Required Certs:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="certs"
              v-model="skill.certs"
              :clearable="false"
               multiple
              >
            </v-select>
          </div>
          <div class="mb-3">
            <button class="btn btn__primary" @click="updateSkill()">
              Update Skill
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'skill',
  data: () => ({
    performingRequest: false,
  }),
  created () {
    this.$store.dispatch("getSkillFromId", this.$route.params.id)
    this.$store.dispatch("getSkillCategories")
    this.$store.dispatch("getCerts")
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'skill', 'skillCategories', 'certs']),
  },
  components: {
    Loader,
  },
  methods: {
    // updateStatus(row) {
    //   fb.backgroundChecksCollection.doc(row.id).update(row)
    // },
    async updateSkill() {
      this.performingRequest = true
      await fb.skillsCollection.doc(this.skill.id).update(this.skill)
      this.performingRequest = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearSkill")
    this.$store.dispatch("clearCerts")
    this.$store.dispatch("clearSkillCategories")
  }
}
</script>